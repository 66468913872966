import Icon from "core/ui-kit/icon";

import isNull from "core/helpers/typeChecks/isNull";

import isMenuItemAvailable from "./isMenuItemAvailable";

const menuItemMapper = (
    item,
    userRole, 
    projectId,
    permissionsUtils,
    t
) => {
    const hasChildren = item.items && item.items.length > 0;
	let isAvailable = false;
	let hasAvailableChild = false;

	if (!hasChildren) {
		isAvailable = isMenuItemAvailable(
			item,
			projectId,
			userRole,
			permissionsUtils
		);
	} else {
		hasAvailableChild = item.items
			.map(item =>
				menuItemMapper(
					item,
					userRole,
					projectId,
					permissionsUtils,
                    t
				),
			)
			.some(item => !!item);
	}

	if (!isAvailable && !hasAvailableChild) {
		return null;
	}

	return {
		key: item.key,
		icon: item.icon ? <Icon name={item.icon} className="rt--font-bigest" /> : null,
		label: t(item.title),
        url: item.url,
		//popupClassName: hasChildren ? "rt--sidebar-content-menu-sub" : "",
		children: hasChildren
			? item.items.map(item =>
                menuItemMapper(
                    item,
                    userRole,
                    projectId,
                    permissionsUtils,
                    t,
                ),
			).filter(item => !isNull(item))
			: null,
	};
}

const mapMenuItems = (
    items,
    userRole, 
    projectId,
    permissionsUtils,
    t
) => {
    return items.map(item =>
		menuItemMapper(
			item,
			userRole,
			projectId,
			permissionsUtils,
            t
		),
	).filter(item => !isNull(item))
}

export default mapMenuItems;