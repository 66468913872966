import getAPIUrls from "core/helpers/api/getAPIUrls";
import isObjectEmpty from "core/helpers/common/isObjectEmpty";
import isArray from "core/helpers/typeChecks/isArray";
import isNull from "core/helpers/typeChecks/isNull";
import isUndefined from "core/helpers/typeChecks/isUndefined";

export const getURLMergedWithQueryData = ( url, queryData ) => {
	const urlInstance = new URL(getAPIUrls().API_URL +url);

	const paramsNotProvided = isUndefined(queryData) || isObjectEmpty(queryData);

	if (paramsNotProvided) {
		return urlInstance.href;
	}

	const paramEntries = Object.entries(queryData);

	paramEntries.forEach(([key, value]) => {
		if (isUndefined(value) || isNull(value)) {
			return;
		}

		if (isArray(value)) {
			return value.forEach((item) => {
				urlInstance.searchParams.append(key, item);
			});
		}

		urlInstance.searchParams.append(key, value.trim());
	});

	return urlInstance.href;
};

export const getURL = url => {
    return url;
	const baseURL = getAPIUrls().API_URL;

	return `${baseURL}${url}`;
};

export const handleRequestBody = requestBody => {
    return requestBody
    // if(!requestBody){
    //     return requestBody
    // }

    // const isFormData = requestBody instanceof FormData;
    // if(isFormData){
    //     return requestBody;
    // } 

    // const data = {};

    // Object.keys(requestBody).map(key => {
    //     if(typeof requestBody[key] === "string"){
    //         if(typeof key === "string" && !key.toLowerCase().includes("password")){
    //             data[key] = requestBody[key].trim();
    //         } else {
    //             data[key] = requestBody[key]
    //         }
    //         if(data[key] === ""){
    //             data[key] = null
    //         }
    //     } else {
    //         data[key] = requestBody[key]
    //     }
    // })

    // return data;
}