import ROUTES from "core/constants/routes/routes";

export const PATHS_TO_SHOW_COMPANIES_WITHOUT_PROJECT = [
    ROUTES.COMPANIES,
    ROUTES.COMPANIES_EDIT + "/:id",
    ROUTES.PROJECTS,
    ROUTES.PROJECTS_EDIT + "/:id",
    ROUTES.PERMISSIONS,
    ROUTES.PERMISSION_GROUPS,
    ROUTES.PERMISSION_GROUPS_EDIT + "/:id",
    ROUTES.USERS,
    ROUTES.USERS_EDIT + "/:id",
    ROUTES.ACCESS_MANAGERS,
    ROUTES.ACCESS_MANAGERS_EDIT + "/:id",
    ROUTES.PERMISSION_REQUESTS,
    ROUTES.USER_LOGS,
    ROUTES.TRANSLATIONS_PROMO,
    ROUTES.TRANSLATIONS_BO,
    ROUTES.SETTINGS_CURRENCIES,
    ROUTES.SETTINGS_LANGUAGES,
    ROUTES.DEVELOPER_ERRORS,
    ROUTES.DEVELOPER_JOBS,
    ROUTES.DEVELOPER_MONITORING,
    ROUTES.DEVELOPER_PLATFORM_TEST,
    ROUTES.DEVELOPER_POST_DEPLOYMENT_ACTIONS,
    ROUTES.DEVELOPER_DB_CONNECTIONS,
    ROUTES.PERMISSION_REQUESTS
]

export const PATHS_TO_SHOW_DROPDOWN_DISABLED = [
    ROUTES.COMPANIES_EDIT + "/:id",
    ROUTES.PROJECTS_EDIT + "/:id",
    ROUTES.AFFILIATES_EDIT + "/:id",
    ROUTES.AFFILIATE_MANAGERS_EDIT + "/:id",
    ROUTES.USERS_EDIT + "/:id",
    ROUTES.PERMISSION_GROUPS_EDIT + "/:id",
    ROUTES.SETTINGS_PAYMENTS,
    ROUTES.SETTINGS_PAYMENTS_EDIT + "/:id",
    ROUTES.CAMPAIGNS_EDIT + "/:id",
    ROUTES.AFFILIATE_GROUPS_EDIT + "/:id"
]

export const QUERY_PARAMS_TO_SHOW_DROPDOWN_DISABLED = [
    "newsletterId", 
    "newsletterName"
]

export const COMPANIES_DROPDOWN_MENU_MOBILE_TYPES = {
    COMPANIES: "companies",
	PROJECTS: "projects"
}