import { isMobile, toUpperCaseFirstLetter } from "utils/common";

import { FINANCIAL_ENTITY_TYPE, OPERATIONAL_ENTITY_TYPE, PIE_CHART_STATES, TIME_PERIOD } from "../constants";

/** Function to fill array wirh zeros, to extends it's lenth
     * @function
     * @param {Array} arr - arr
     * @param {number} length - new array length
     * @returns {Array} - new array
 */
const fillWithZeros = (arr, length) => new Array(length).fill(0).map((el, i) => arr[i] === undefined ? el : arr[i])

const getOperationsPieChartTooltip = (element, entityType, t) => {
    let entityTypeText = ""

    switch (entityType) {
        case OPERATIONAL_ENTITY_TYPE.AFFILIATE:
            entityTypeText = t("backoffice.dashboard.affiliate")
            break;
        case OPERATIONAL_ENTITY_TYPE.CAMPAIGN:
            entityTypeText = t("backoffice.dashboard.campaign")
            break;
        case OPERATIONAL_ENTITY_TYPE.MEDIA:
            entityTypeText = t("backoffice.dashboard.media")
            break;
        case OPERATIONAL_ENTITY_TYPE.PLAYER:
            entityTypeText = t("backoffice.dashboard.player")
            break;
        default:
            break;
    }
    return `<div class="rt--chart-operational-tooltip">
                <div class="rt--flex rt--flex-col"> 
                    <div class="rt--flex rt--flex-col rt--mb-4">
                        <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>${element.key}</span> 
                        <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>${element.y + ' ' + entityTypeText}</span>
                    </div>
                </div>
            </div>`;
}

const makeOperationsPieChartSeries = (chartData, inactiveStates, entityType, t) => {
    const stateTitles = {};

    const availableStates = PIE_CHART_STATES[entityType] ?? {};

    switch(entityType){
        case OPERATIONAL_ENTITY_TYPE.AFFILIATE:
            stateTitles[availableStates["ACTIVE"]] = {
                title: t("backoffice.affiliates.approved"),
                y: chartData.data[availableStates["ACTIVE"]],
                color: 'rgba(0, 186, 136, 1)',
                className: 'HighchartApproved',
                arrayIndex: 0
            }
            stateTitles[availableStates["IN_PROGRESS"]] = {
                title: t("backoffice.common.pending"),
                y: chartData.data[availableStates["IN_PROGRESS"]],
                color: 'rgba(244, 183, 64, 1)',
                className: 'HighchartPending',
                arrayIndex: 1
            }
            stateTitles[availableStates["BLOCKED"]] = {
                title: t("backoffice.common.blocked"),
                y: chartData.data[availableStates["BLOCKED"]],
                color: 'rgba(237, 46, 126, 1)',
                className: 'HighchartBlocked',
                arrayIndex: 2
            }

            stateTitles[availableStates["DECLINED"]] = {
                title: t("backoffice.common.declined"),
                y: chartData.data[availableStates["DECLINED"]],
                color: 'rgba(200, 203, 229, 1)',
                className: 'HighchartDeclined',
                arrayIndex: 3
            }
            
            break;
        case OPERATIONAL_ENTITY_TYPE.MEDIA:
            stateTitles[availableStates["DIRECT_LINK"]] = {
                title: t("backoffice.campaigns.directLink"),
                y: chartData.data[availableStates["DIRECT_LINK"]],
                color: 'rgba(58, 134, 254, 1)',
                className: 'HighchartMediaDirect',
                arrayIndex: 0
            };
            stateTitles[availableStates["BANNER_CODE"]] = {
                title: t("backoffice.campaigns.mediaCode"),
                y: chartData.data[availableStates["BANNER_CODE"]],
                color: 'rgba(131, 56, 236, 1)',
                className: 'HighchartMediaCode',
                arrayIndex: 1
            }
            break;
        case OPERATIONAL_ENTITY_TYPE.CAMPAIGN:
            stateTitles[availableStates["ACTIVE"]] = {
                title: t("backoffice.common.active"),
                y: chartData.data[availableStates["ACTIVE"]],
                color: 'rgba(0, 186, 136, 1)',
                className: 'HighchartApproved',
                arrayIndex: 0
            }
            stateTitles[availableStates["INACTIVE"]] = {
                title: t("backoffice.common.inactive"),
                y: chartData.data[availableStates["INACTIVE"]],
                color: 'rgba(200, 203, 229, 1)',
                className: 'HighchartDeclined',
                arrayIndex: 1
            }
            break;
    }

    let data = [];
    const states = Object.values(availableStates);

    states.forEach(state => {
        data.push(
            {
                name: stateTitles[state].title,
                y: stateTitles[state].y,
                color: stateTitles[state].color,
                className: stateTitles[state].className,
            }
        )
    });

    const nonZeroSeries = Object.keys(chartData.data);

    const activeSeries = nonZeroSeries.filter(s => {
        const title = stateTitles[s]?.title;
        return !inactiveStates.includes(title)
    });

    const activeSeriesIndexes = activeSeries.map(s => stateTitles[s]?.arrayIndex);
    const activeSeriesTotal = activeSeries.map(s => chartData.data[s]).reduce((a, acc) => a + acc, 0);

   
    let dataIndex = 0;

    let emptySpaceSize = activeSeriesTotal * 2 / 100;

    data = Array.from({ length: (2 * data.length) }, (_, i) => {
        if (
            i % 2 === 1
        ) {
            return {
                y: activeSeries.length > 1 && activeSeriesIndexes.includes((i - 1) / 2) ? emptySpaceSize : 0,
                className: 'disabled',
            }
        }
        return data[dataIndex++];
    })

    return {
        data,
        total: activeSeriesTotal
    };
}

export const getOperationsPieChartOptions = (
    chartData, 
    entityType, 
    inactiveStates, 
    onItemClick,
    t
) => {

    const { data, total } = makeOperationsPieChartSeries(chartData, inactiveStates, entityType, t);

    return {
        chart: {
            type: 'pie',
            height: isMobile() ? 360 : 125,
            width: isMobile() ? 300 : 360,
            plotBackgroundColor: "#F7F8FA",
            backgroundColor: "#F7F8FA",
        },

        accessibility: {
            enabled: false
        },

        title: {
            align: 'center',
            verticalAlign: 'middle',
            x: (isMobile() ? 3 : -120),
            y: (isMobile() ? -9 : 18),
            text: `<span class="rt--chart-operational-pie-circle-title rt--font-extra-bold ${isMobile() ? ' rt--font-bigest' : ' rt--font-big'}">${total}</span>`,
        },

        tooltip: {
            useHTML: true,
            borderWidth: 0,
            backgroundColor: "transparent",
            shadow: false,
            formatter: function () {
                return getOperationsPieChartTooltip(this, entityType, t)
            }
        },

        credits: {
            enabled: false
        },

        legend: {
            verticalAlign: 'middle',
            itemStyle: {
                fontSize: isMobile() ? '14px' : '12px',
                color: "#14142B",
                fontWeight: '500'
            },
            navigation: {
                enabled: false,
            }
        },


        plotOptions: {
            pie: {
                size: isMobile() ? '195px' : '105px',
                startAngle: -25,
                center: isMobile() ? ["49.5%", "39%"] : ["9.5%", "56%"],
                showInLegend: true,
                point: {
                    events: {
                        legendItemClick: function (e) {

                            const name = e.target.name;
                            const isVisible = e.target.visible;
                            onItemClick && onItemClick(name, isVisible)

                        }
                    }
                }
            },
            series: {
                borderRadius: 3,
                innerSize: '72%',
                dataLabels: {
                    enabled: false,
                }
            }
        },

        series: [{
            data,
        }],

    }
}


/** Function to get line chart data
     * @function
     * @param {object} chart - chart loaded data
     * @param {number} period - tperiod
     * @param {string} valuePropName - name of the property from which the data is to be constructed
     * @returns {object} - chart data
 */
export const getLineChartData = (
    chart, 
    period, 
    valuePropName = "count", 
    dateService,
    t
) => {

    const currentMonthDaysCount = dateService.getCurrentMonthDaysCount();

    let categories = [];
    let values = [];
    let dates = [];

    let counts = chart.map(element => element[valuePropName] ?? 0);
    let times = chart.map(element => element.time);

    const timePeriod = period?.period;

    let totalMonths;
    let toYMD = dateService.getDateYMD(period?.to);
    let fromYMD = dateService.getDateYMD(period?.from);

    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            categories = dateService.getAllFormatedHours();
            values = fillWithZeros(counts, 24);
            dates = dateService.getAllFormatedHours();
            break;
        case TIME_PERIOD.YESTERDAY:
            categories = chart.map(element => dateService.format(element.time, true, false, false));
            values = counts;
            dates = times;
            break;
        case TIME_PERIOD.THIS_MONTH:
            categories = fillWithZeros([], Math.ceil(currentMonthDaysCount / 7)).map((_, i) => t(`backoffice.dashboard.week`) + " " + (i + 1));
            counts = fillWithZeros(counts, currentMonthDaysCount);
            values = fillWithZeros([], Math.ceil(currentMonthDaysCount / 7))
                .map((_, i) => counts.slice(i * 7, (i + 1) * 7).reduce((acc, a) => acc + a, 0));
            dates = dateService.getCurrentMonthDays();
            break;
        case TIME_PERIOD.LAST_MONTH:
            categories = fillWithZeros([], Math.ceil(chart.length / 7)).map((_, i) => t(`backoffice.dashboard.week`) + " " + (i + 1));
            values = fillWithZeros([], Math.ceil(chart.length / 7))
                .map((_, i) => counts.slice(i * 7, (i + 1) * 7).reduce((acc, a) => acc + a, 0));
            dates = times;
            break;
        case TIME_PERIOD.LAST_90_DAYS:
            categories = chart.map(element => dateService.toString(element.time, "MMM"));
            values = counts;
            dates = times;
            break;
        case TIME_PERIOD.THIS_YEAR:
            categories = dateService.getMonths().map(month => toUpperCaseFirstLetter(month));
            values = fillWithZeros(counts, 12);
            dates = dateService.getAllFormatedMonths();
            break;
        case TIME_PERIOD.CUSTOM:
            totalMonths = (toYMD.year - fromYMD.year) * 12 + toYMD.month - fromYMD.month;
            if(totalMonths === 0){
                categories = chart.map(element => dateService.toString(element.time, "DD"));
                values = counts;
                dates = times;
            } else if(totalMonths === 1){
                categories = fillWithZeros([], Math.ceil(chart.length / 7)).map((_, i) => t(`backoffice.dashboard.week`) + " " + (i + 1));
                values = fillWithZeros([], Math.ceil(chart.length / 7))
                    .map((_, i) => counts.slice(i * 7, (i + 1) * 7).reduce((acc, a) => acc + a, 0));
                dates = times;
            } else {
                categories = chart.map(element => dateService.toString(element.time, "MMM"));
                values = counts;
                dates = times;
            }
            break;
        default:
            return chart.map(element => element.time)
    }

    return {
        categories,
        values,
        dates,
        counts
    }
}


const tooltipInnerHTML = ({
    title,
    content,
    additional = "",
}) => {
    return `
        <div class="rt--chart-operational-tooltip">
            <div class="rt--flex rt--flex-col">
                <div class="rt--flex rt--flex-col rt--mb-4">
                    <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                        ${title}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                        ${content}
                    </span>
                </div>
            </div>
            ${additional}
        </div>
    `
}

const additionalContentHTML = ({ dateTime, value }) => {
    return `
        <div class="rt--flex rt--align-center rt--justify-between rt--mt-8">
            <span class='rt--chart-operational-tooltip-title rt--chart-operational-tooltip-title-point rt--font-small rt--font-bold rt--mr-12 rt--pl-10'>
                ${dateTime}
            </span>
            <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold'>
                ${value}
            </span>
        </div>
    `
}

const contentHTML = ({ title, value, subTitle1, subTitle2, subValue1, subValue2 }) => {
    return `
        <div class="rt--flex rt--flex-col">
            ${Boolean(title) || Boolean(value)
            ? (
                `<div class="rt--flex rt--flex-col rt--mb-8">
                    <span class='rt--chart-operational-tooltip-title .rt--font-normal rt--font-bold'>
                        ${title ? title : ""}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle .rt--font-normal rt--font-extra-bold rt--mt-6'>
                        ${value ? value : ""}
                    </span>
                </div>`
            ) : (
                ""
            )}
            <div class="rt--flex">
            ${Boolean(subTitle1)
            ? (
                `<div class="rt--flex rt--flex-col rt--mr-16">
                    <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                        ${subTitle1}
                    </span>
                    <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                        ${subValue1}
                    </span>
                </div>`
            ) : (
                ""
            )}

            ${Boolean(subTitle2)
            ? (
                `<div class="rt--flex rt--flex-col">
                        <span class='rt--chart-operational-tooltip-title rt--font-small rt--font-bold'>
                            ${subTitle2}
                        </span>
                        <span class='rt--chart-operational-tooltip-subTitle rt--font-small rt--font-extra-bold rt--mt-6'>
                            ${subValue2}
                        </span>
                    </div>`
            ) : "" }
            </div>
        </div>
    `
}

export const getOperationsLineChartTooltip = ({ 
    element, 
    chartData, 
    period, 
    entityType, 
    dateService,
    t 
}) => {
    let x = element.x;
    let y = element.y;
    const index = element.point.index;
    const lineIndex = element.series.index;
    let list = "";
    const dates = chartData[lineIndex].dates;
    const counts = chartData[lineIndex].counts;

    let entityTypeText = ""

    switch (entityType) {
        case OPERATIONAL_ENTITY_TYPE.PLAYER:
            entityTypeText = t("backoffice.dashboard.player")
            break;
        case OPERATIONAL_ENTITY_TYPE.DEPOSIT_COUNT:
            entityTypeText = t("backoffice.dashboard.player")
            break;
        default:
            break;
    }

    y = y + " " + entityTypeText;

    let startIndex, endIndex;

    const timePeriod = period?.period;

    let totalMonths;
    let toYMD = dateService.getDateYMD(period?.to);
    let fromYMD = dateService.getDateYMD(period?.from);

    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            x = dateService.format(dateService.getNow(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.YESTERDAY:
            x = dateService.format(dateService.yesterday(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.THIS_MONTH:
        case TIME_PERIOD.LAST_MONTH:
            startIndex = index * 7;
            endIndex = Math.min((index + 1) * 7, dates.length);
            for (let i = startIndex; i < endIndex; i++) {
                list += additionalContentHTML({
                    dateTime: dateService.toString(dates[i], "MMM DD"),
                    value: `${counts[i]} ${entityTypeText}`
                })
            }
            break;
        case TIME_PERIOD.LAST_90_DAYS:
        case TIME_PERIOD.THIS_YEAR:
            x = dateService.toString(dates[index], "MMMM YYYY");
            break;
        case TIME_PERIOD.CUSTOM:
            totalMonths = (toYMD.year - fromYMD.year) * 12 + toYMD.month - fromYMD.month;
            if(totalMonths === 0){
                x = dateService.format(dates[index], false, true, false);
            } else if(totalMonths === 1){
                startIndex = index * 7;
                endIndex = Math.min((index + 1) * 7, dates.length);
                for (let i = startIndex; i < endIndex; i++) {
                    list += additionalContentHTML({
                        dateTime: dateService.toString(dates[i], "MMM DD"),
                        value: `${counts[i]} ${entityTypeText}`
                    })
                }
            } else {
                x = dateService.toString(dates[index], "MMMM YYYY");
            }
            break;
        default:
            break;
    }

    return tooltipInnerHTML({ title: x, content: y, additional: list });
}

const financialTooltipValueFormatter = ({
    value,
    currencyCode,
    formatAmount,
    formatCurrencyWithSymbol
}) => {
    value = formatAmount(value, currencyCode);
    return formatCurrencyWithSymbol(currencyCode, value, false);
}

export const getFinancialLineChartTooltip = (element, additionalData) => {
    let xAxisValue = element.x;
    let index = element.point.index;
    let yAxisValue = element.y;

    const lineName = element.series.name;

    const {
        dates,
        counts,
        currencyCode,
        period,
        entityType, 
        dateService,
        formatAmount,
        formatCurrencyWithSymbol,
        t
    } = additionalData;

    const formatter = value => financialTooltipValueFormatter({
        value,
        currencyCode,
        formatAmount,
        formatCurrencyWithSymbol
    });

    let fieldName = null;
    let sub1FieldName = null;
    let sub2FieldName = null;

    switch(entityType){
        case FINANCIAL_ENTITY_TYPE.GGR:
            if(lineName.includes("type-ggr")){
                fieldName = "ggr";
                sub1FieldName = "sportGGR";
                sub2FieldName = "casinoGGR";
            } else if(lineName.includes("type-ngr")){
                fieldName = "ngr";
                sub1FieldName = "sportNGR";
                sub2FieldName = "casinoNGR";
            }
            break;
        case FINANCIAL_ENTITY_TYPE.TURNOVER:
            if(lineName.includes("type-turnover")){
                fieldName = "turnover";
                sub1FieldName = "sportTurnover";
                sub2FieldName = "casinoTurnover";
            } 
            break;
        case FINANCIAL_ENTITY_TYPE.DEPOSIT_AMOUNT:
            if(lineName.includes("type-depositAmount")){
                fieldName = "deposit";
            } else if(lineName.includes("type-ftdAmount")){
                fieldName = "ftdAmount";
            } else if(lineName.includes("type-netDeposit")){
                fieldName = "netDeposit";
            }
            break;
        case FINANCIAL_ENTITY_TYPE.EARNING_COMMISSIONS_AMOUNT:
            if(lineName.includes("type-cpa")){
                fieldName = "cpaAmount";
            } else if(lineName.includes("type-earning")){
                fieldName = "totalCommissionsAmount";
            } 
        default:
            break;
    }

    let tooltipTitle = xAxisValue; // default
    let content = formatter(yAxisValue); // default
    let additionalContent = "" // default

    const timePeriod = period?.period;

    let totalMonths;
    let toYMD = dateService.getDateYMD(period?.to);
    let fromYMD = dateService.getDateYMD(period?.from);
    if(timePeriod === TIME_PERIOD.CUSTOM){
        totalMonths = (toYMD.year - fromYMD.year) * 12 + toYMD.month - fromYMD.month;
    }

    //#region ------------------------------------ TOOLTIP TITLE -------------------------------------- //

    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            tooltipTitle = dateService.format(dateService.getNow(), false, true, false) + " " + xAxisValue;
            break;
        case TIME_PERIOD.YESTERDAY:
            tooltipTitle = dateService.format(dateService.yesterday(), false, true, false) + " " + xAxisValue;
            break;
        case TIME_PERIOD.LAST_90_DAYS:
        case TIME_PERIOD.THIS_YEAR:
            tooltipTitle = dateService.toString(dates[index], "MMMM YYYY");
            break;
        case TIME_PERIOD.CUSTOM:
            if(totalMonths === 0){
                tooltipTitle = dateService.format(dates[index], false, true, false);
            } else if(totalMonths !== 1){
                tooltipTitle = dateService.toString(dates[index], "MMMM YYYY");
            } 
            break;
        default:
            break;
    }

    //#endregion

    //#region ------------------------------------ TOOLTIP CONTENT -------------------------------------- //
    
    if([FINANCIAL_ENTITY_TYPE.GGR, FINANCIAL_ENTITY_TYPE.TURNOVER].includes(entityType)){
        let data = {};
        if(
            ( timePeriod === TIME_PERIOD.THIS_MONTH || timePeriod === TIME_PERIOD.LAST_MONTH ) ||
            ( timePeriod === TIME_PERIOD.CUSTOM && totalMonths === 1)
        ){
            let sport = 0;
            let casino = 0;
            const startIndex = index * 7;
            const endIndex = Math.min((index + 1) * 7, dates.length);

            for (let i = startIndex; i < endIndex; i++) {
                sport += counts?.[sub1FieldName]?.[i] ?? 0;
                casino += counts?.[sub2FieldName]?.[i] ?? 0;
            }

            data = {
                subTitle1: t("backoffice.dashboard.sport"),
                subValue1: formatter(sport),
                subTitle2: t("backoffice.dashboard.casino"),
                subValue2: formatter(casino)
            }

        } else {
            data = {
                subTitle1: t("backoffice.dashboard.sport"),
                subValue1: formatter(counts?.[sub1FieldName]?.[index] ?? 0),
                subTitle2: t("backoffice.dashboard.casino"),
                subValue2: formatter(counts?.[sub2FieldName]?.[index] ?? 0),
            }
        }

        data.title = t("backoffice.dashboard.total");
        data.value = formatter(yAxisValue);
        content = contentHTML({
            ...data
        })
    }


    //#region ------------------------------ TOOLTIP ADDITIONAL CONTENT ------------------------------- //

    if(
        ( timePeriod === TIME_PERIOD.THIS_MONTH || timePeriod === TIME_PERIOD.LAST_MONTH ) ||
        ( timePeriod === TIME_PERIOD.CUSTOM && totalMonths === 1)
    ) {
        console.log({counts, fieldName})
        const startIndex = index * 7;
        const endIndex = Math.min((index + 1) * 7, dates.length);
        for (let i = startIndex; i < endIndex; i++) {
            additionalContent += (
                additionalContentHTML({
                    dateTime: dateService.toString(dates[i], "MMM DD"),
                    value: formatter(counts?.[fieldName]?.[i] ?? 0)
                })
            )
        }
    }

    //#endregion
    return tooltipInnerHTML({
        title: tooltipTitle,
        content,
        additional: additionalContent,
    })
}

export const makeTimePeriodCustomRangeOutputValue = (from, to, dateService) => {

    let fromDate = from;
    if(fromDate){
        fromDate = dateService.setUnit(fromDate, "hour", 0);
        fromDate = dateService.setUnit(fromDate, "minute", 0);
        fromDate = dateService.setUnit(fromDate, "second", 0);
        fromDate = dateService.setUnit(fromDate, "millisecond", 0);
    }
    let toDate = to;
    if(toDate){
        toDate = dateService.addUnit(toDate, 1, "days");
        toDate = dateService.setUnit(toDate, "hour", 0);
        toDate = dateService.setUnit(toDate, "minute", 0);
        toDate = dateService.setUnit(toDate, "second", 0);
        toDate = dateService.setUnit(toDate, "millisecond", 0);
    }

    return {
        from : fromDate ? dateService.toString(fromDate) : null,
        to : toDate ? dateService.toString(toDate) : null
    }
}

export const getMarketingLineChartTooltip = ({ 
    element, 
    chartData, 
    period, 
    dateService,
    t 
}) => {
    let x = element.x;
    let y = element.y;
    const index = element.point.index;
    const lineIndex = element.series.index;
    let list = "";
    const dates = chartData[lineIndex].dates;
    const counts = chartData[lineIndex].counts;

    let entityText = ""

    switch (lineIndex) {
        case 0:
            entityText = t("backoffice.dashboard.view")
            break;
        case 1:
            entityText = t("backoffice.dashboard.validClick")
            break;
        case 2:
            entityText = t("backoffice.dashboard.invalidClick")
            break;
        default:
            break;
    }

    y = y + " " + entityText;

    let startIndex, endIndex;

    const timePeriod = period?.period;

    let totalMonths;
    let toYMD = dateService.getDateYMD(period?.to);
    let fromYMD = dateService.getDateYMD(period?.from);

    switch (timePeriod) {
        case TIME_PERIOD.TODAY:
            x = dateService.format(dateService.getNow(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.YESTERDAY:
            x = dateService.format(dateService.yesterday(), false, true, false) + " " + x;
            break;
        case TIME_PERIOD.THIS_MONTH:
        case TIME_PERIOD.LAST_MONTH:
            startIndex = index * 7;
            endIndex = Math.min((index + 1) * 7, dates.length);
            for (let i = startIndex; i < endIndex; i++) {
                list += additionalContentHTML({
                    dateTime: dateService.toString(dates[i], "MMM DD"),
                    value: `${counts[i]} ${entityText}`
                })
            }
            break;
        case TIME_PERIOD.LAST_90_DAYS:
        case TIME_PERIOD.THIS_YEAR:
            x = dateService.toString(dates[index], "MMMM YYYY");
            break;
        case TIME_PERIOD.CUSTOM:
            totalMonths = (toYMD.year - fromYMD.year) * 12 + toYMD.month - fromYMD.month;
            if(totalMonths === 0){
                x = dateService.format(dates[index], false, true, false);
            } else if(totalMonths === 1){
                startIndex = index * 7;
                endIndex = Math.min((index + 1) * 7, dates.length);
                for (let i = startIndex; i < endIndex; i++) {
                    list += additionalContentHTML({
                        dateTime: dateService.toString(dates[i], "MMM DD"),
                        value: `${counts[i]} ${entityText}`
                    })
                }
            } else {
                x = dateService.toString(dates[index], "MMMM YYYY");
            }
            break;
        default:
            break;
    }

    return tooltipInnerHTML({ title: x, content: y, additional: list });
}