
//#region react
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from "react-router-dom";
//#endregion

//#region components
import Modal from 'components/common/modal';
import RegisterForm from 'components/auth/register/registerForm';
//#endregion

//#region actions
import {
    getAffiliates,
    createAffiliate
} from "store/actions/portal/affiliates/affiliates/affiliates.action";
//#endregion

//#region utils
import { isMobile } from 'utils/common';
import { hasAffilaiteEditPageAccess } from "utils/pageAccess";
//#endregion

//#region constants
import ROUTES from "core/constants/routes/routes";
import { POPUP_SIZE } from 'constants/common.constants';
//#endregion

import usePermissions from "core/hooks/usePermission";

const CreateAffiliate = ({ 
    projectId,
    registerFormRef,
    createAffiliate,
    getAffiliates, 
    onClose 
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const isMobileDevice = isMobile();

    const navigate = useNavigate();


    return (
        <Modal
            title={t('backoffice.affiliates.createAffiliate')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.create')}
            onOk={() => registerFormRef.current?.handleForm()}
            onCancel={onClose}
            cancelButtonProps={{ disabled: registerFormRef.current?.isSaving }}
            isLoading={registerFormRef.current?.isSaving}
            size={POPUP_SIZE.BIG}
        >
            <RegisterForm
                id={projectId}
                formInstaceRef={registerFormRef}
                getFieldLabel={(control) => t('backoffice.registrationform.' + control.name)}
                getScrollArea={() => document.getElementsByClassName(isMobileDevice ? "ant-drawer-body" : "ant-modal-body")[0]}
                getFieldPopupContainer={(id) => document.getElementById(id)}
                onRegisterSuccess={record => {
                    if (hasAffilaiteEditPageAccess(permissionUtils)) {
                        navigate({
                            pathname: `${ROUTES.AFFILIATES_EDIT}/${record.id}`,
                            search: `name=${record.userName}&longId=${record.longId}&state=${record.state}`
                        })
                    } else {
                        getAffiliates();
                        onClose();
                    }
                }}
                registerAction={createAffiliate}
            />
        </Modal>
    )
};

CreateAffiliate.propTypes = {
    /** React prop, given project Id */
    projectId: PropTypes.string,
    /** React prop, given Register Form Instance Ref */ 
    registerFormRef: PropTypes.object,
    /** React prop, to close Modal */ 
    onClose: PropTypes.func, 
    /** Redux action to get affiliates */
    getAffiliates: PropTypes.func,
    /** Redux action to create Affiliate */
    createAffiliate: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getAffiliates: nextPage => {
        dispatch(getAffiliates(nextPage));
    },
    createAffiliate: (data, token, languageCode, onSuccess) => {
        dispatch(createAffiliate(data, token, languageCode, onSuccess));
    }
});

export default connect(null, mapDispatchToProps)(CreateAffiliate);