import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import NumericInput from 'components/common/numericInput';
import TextAreaInput from 'components/common/textAreaInput';
import TransferResult from '../transferResult';

import useCurrencies from 'hooks/useCurrencies';

import { affilaiteDepositToEarningBalance, affilaiteWithdrawFromEarningBalance } from "store/actions/portal/wallet/transfer.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { WALLET_ADJUSTMENT_TYPE, WALLET_TYPE } from 'constants/wallet.constants';
import { AMOUNT_REGEX } from 'constants/regex.constants';

import affiliateType from 'types/affiliate/generalInfo.type';


/** Adjust Balance Popup Component */
const AdjustBalanceComponent = ({
    affiliate,
    affilaiteDepositToEarningBalance,
    affilaiteWithdrawFromEarningBalance,
    isSaving,
    showTransferResult,
    onClose
}) => {
    const { t } = useTranslation();
    const [formInstance] = Form.useForm();

    const [ currencies ] = useCurrencies();

    const { validateFields } = formInstance;

    /** Selected Currency */
    const [selectedCurrency, setSelectedCurrency] = useState(null);

    /** Selected Wallet Earning Balance */
    const selectedWalletEarningBalance = useMemo(() => {
        if (!selectedCurrency) return null;
        const wallet = affiliate.wallets.find(w => w.currencyCode.toLowerCase() === selectedCurrency.toLowerCase());
        if (!wallet) return 0;
        return wallet[WALLET_TYPE.EARNING_BALANCE]?.amount ?? 0
    }, [selectedCurrency])

    /** Fires when form submitted
       * @function
       * @memberOf AdjustBalanceComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                const d = {
                    amount: data.amount,
                    affiliateId: affiliate.id,
                    currencyCode: data.currencyCode,
                    notes: data.notes
                }

                if (data.type === WALLET_ADJUSTMENT_TYPE.MANUAL_BONUS) {
                    affilaiteDepositToEarningBalance(d);
                } else {
                    affilaiteWithdrawFromEarningBalance(d);
                }

            }).catch(ex => {
                console.log(ex)
            })
    }

    return showTransferResult ? (
        <TransferResult 
            onClose={onClose}
        />
    ) :
        (
            <Modal
                title={t('backoffice.wallet.adjustEarningBalance')}
                cancelText={t('backoffice.common.cancel')}
                okText={t('backoffice.wallet.adjust')}
                onOk={handleForm}
                onCancel={onClose}
                isLoading={isSaving}
                size={POPUP_SIZE.MIDDLE}
            >
                <Form
                    className="rt--form"
                    form={formInstance}
                    colon={false}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        type: WALLET_ADJUSTMENT_TYPE.MANUAL_DEDUCTION,
                        amount: ""
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item>
                                <div className='rt--info rt--pl-16 rt--pr-16 rt--pt-12 rt--pb-12'>
                                    <div className='rt--flex rt--align-center rt--mb-4'>
                                        <span className='rt--text-light rt--font-normal rt--font-bold'>
                                            {t('backoffice.wallet.username')}:
                                        </span>
                                        <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4 rt--pr-4'>
                                            {affiliate.userName}
                                        </span>
                                    </div>
                                    <div className='rt--flex rt--align-center rt--mb-4'>
                                        <span className='rt--text-light rt--font-normal rt--font-bold'>
                                            {t('backoffice.wallet.affiliateId')}:
                                        </span>
                                        <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4 rt--pr-4'>
                                            {affiliate.longId}
                                        </span>
                                    </div>
                                    <div className='rt--flex rt--align-center rt--mb-4'>
                                        <span className='rt--text-light rt--font-normal rt--font-bold'>
                                            {t('backoffice.wallet.earningBalance')}:
                                        </span>
                                        <span className='rt--text-secondary rt--font-normal rt--font-bold rt--pl-4 rt--pr-4'>
                                            {selectedWalletEarningBalance !== null ? `${selectedWalletEarningBalance} ${selectedCurrency}` : "-"}
                                        </span>
                                    </div>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label={t('backoffice.wallet.transactionType')}
                                name="type"
                            >
                                <Select
                                    options={[
                                        { value: WALLET_ADJUSTMENT_TYPE.MANUAL_DEDUCTION, text: t('backoffice.wallet.manualDeduction') },
                                        { value: WALLET_ADJUSTMENT_TYPE.MANUAL_BONUS, text: t('backoffice.wallet.manualBonus') }
                                    ]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.transactionType')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item
                                label={`${t('backoffice.wallet.currency')} *`}
                                name="currencyCode"
                                rules={[{ required: true, message: t('backoffice.validation.fieldRequired') }]}
                            >
                                <Select
                                    options={
                                        currencies.map(item => (
                                            { value: item.code, text: item.code }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.wallet.currency')}`}
                                    search={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    onChange={currency => setSelectedCurrency(currency)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.wallet.amount')} *`}
                                name="amount"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { pattern: AMOUNT_REGEX, message: t('backoffice.validation.fieldInvalid') },
                                ]}
                                className="rt--general-form-item"
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                validateFirst
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.amount')}`}
                                    maxLength={21}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label={`${t('backoffice.wallet.notes')} *`}
                                name="notes"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 150, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                validateFirst
                                className="rt--general-form-item rt--form-item-without-margin"
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.wallet.notes')}`}
                                    maxLength={150}
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
}

/** AdjustBalanceComponent propTypes
    * PropTypes
*/
AdjustBalanceComponent.propTypes = {
    /** Affiliate  */
    affiliate: affiliateType,
    /** Redux action for affilaite deposit to earning balance */
    affilaiteDepositToEarningBalance: PropTypes.func,
    /** Redux action for affilaite withdraw from earning balance */
    affilaiteWithdrawFromEarningBalance: PropTypes.func,
    /** Redux state property, if true will show transfer result */
    showTransferResult: PropTypes.bool,
    /** Redux state property, is true when transfering agent balance */
    isSaving: PropTypes.bool,
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        affilaiteDepositToEarningBalance: data => {
            dispatch(affilaiteDepositToEarningBalance(data))
        },

        affilaiteWithdrawFromEarningBalance: data => {
            dispatch(affilaiteWithdrawFromEarningBalance(data))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.wallet.isSaving,
        showTransferResult: state.wallet.showTransferResult,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdjustBalanceComponent)