import { useCallback, useEffect, useState } from "react";

import translationService from "core/services/translationService";

export const useTranslation = () => {
	const [locale, setLocale] = useState(translationService.locale);

	const handler = useCallback((locale) => {
		setLocale(locale);
	}, []);


	useEffect(() => {
		translationService.subscribe(handler);

		return () => {
			translationService.unsubscribe(handler);
		};
	}, [handler]);

	const t = (key) => translationService.translate(key);

	return { locale, t };
};
