import React from "react";

import { Navigate } from "react-router-dom";

import usePermissions from "core/hooks/usePermission";
import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from "core/stores/authStore";

import { hasPageAccess } from "./helpers";

/**
 * HOC which navigate to available page if there is no access to page
 */
const withAccessHoc = (WrappedComponent, route) => {

	return (props) => {
        const permissionUtils = usePermissions();
        const projectId = useAuthSelector(globalProjectIdSelector);
        const userRole = useAuthSelector(userRoleSelector);

        const hasAccess = hasPageAccess({
            userRole, 
            permissionUtils, 
            projectId, 
            route
        })

		if (!hasAccess) {
            return <Navigate to={"*"} />;
		}

		return <WrappedComponent {...props} />;
	};
};

export default withAccessHoc;