import axios from "axios";

import getAuthData from "core/helpers/auth/getAuthData";
import isAuthenticated from "core/helpers/auth/isAuthenticated";
import processError from "core/helpers/common/processError";

import AUTHORIZATION from "core/constants/api/authorization";

import TokenManagerService from "core/services/tokenManagerService";

import getAPIUrls from "core/helpers/api/getAPIUrls";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = getAPIUrls().API_URL;

axiosInstance.interceptors.request.use(
    request => {
        //Set default content type
        if (!request.headers['Content-Type'] || request.headers['Content-Type'] === 'text/plain;charset=utf-8') {
            request.headers['Content-Type'] = 'application/json;charset=utf-8';
        }

        const authData = getAuthData();

        if(!authData){
            return request;
        }

        if (!request.headers[AUTHORIZATION]) {
			request.headers[AUTHORIZATION] = "Bearer " + authData.token;
		}
        /** Should be moved to http Service */
        if(request.params){
            const newParams = {}
            Object.keys(request.params).map(key => {
                if(typeof request.params[key] === "string"){
                    if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                        newParams[key] = request.params[key].trim();
                    } else {
                        newParams[key] = request.params[key]
                    }
                    
                    if(newParams[key] === ""){
                        newParams[key] = null
                    }
                } else {
                    newParams[key] = request.params[key]
                }
            })
        }

        if(request.data && !(request.data instanceof FormData)){
            try{
                const newData = {};
                let parsed = request.data;
                if(typeof request.data === 'string'){
                    try{
                        parsed = JSON.parse(request.data);
                    }catch(ex){console.log("err")}
                }

                Object.keys(parsed).map(key => {
                    if(typeof parsed[key] === "string"){
                        if(key && typeof key === "string" && !key.toLowerCase().includes("password")){
                            newData[key] = parsed[key].trim();
                        } else {
                            newData[key] = parsed[key]
                        }
                        if(newData[key] === ""){
                            newData[key] = null
                        }
                    } else {
                        newData[key] = parsed[key]
                    }
                })
                request.data = newData;
            } catch(ex){
                console.log(ex)
            }
        }
        /********* */
        return request;
    },
    error => {
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        /** Abort Call */
        if(error.__CANCEL__){
            return Promise.reject(error);
        }

        /** Nothing Comes from backand, CORS Error */
        if(!error.response){
            return Promise.reject(error).catch(processError);
        }

        if(error.response.status === 401 && isAuthenticated()){
            TokenManagerService.updateToken();
            return TokenManagerService.registerPendingRequest(error.config).catch(processError)
        }

        return Promise.reject(error).catch(processError);
    }
)

export default axiosInstance;