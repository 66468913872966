import GlobalStore from "./store";

import { createSelector } from "core/lib/baseStore";

import { debugStore } from "core/lib/baseStore/debugStore";

export const globalStore = new GlobalStore();

debugStore.registerStore(globalStore);

export const useGlobalSelector = createSelector(globalStore);

export const languagesSelector = state => state.languages;

export const loaderSelector = state => state.isLoading;

export const mainInfoLoaderSelector = state => state.isMainInfoLoading;

export const userInfoSelector = state => state.userInfo;

export const currenciesSelector = state => state.currencies;

export const currencySelector = state => state.currency;

export const companiesSelector = state => state.companies;

export const userPermissionsSelector = state => state.userInfo?.permissions;