import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

import AUTHORIZATION from "core/constants/api/authorization";

const accountService = {
    refreshToken(refreshToken, signal) {
		return httpService.post({
			url: API_URL.REFRESH_TOKEN,
			signal,
			requestBody: {
				refreshToken,
			}
		}).then(({ data: { value } }) => {
            return value;
        });
	},

    authenticate(data, signal){
        return httpService.post({
            url: API_URL.AUTHENTICATE,
            signal,
            requestBody: data
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getApplicationLogos(signal){
        return httpService.get({
            url: API_URL.GET_PROJECT_LOGOS,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getUserInfo(signal){
        return httpService.get({
            url: API_URL.GET_USER_INFO,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    getCurrencies(signal){
        return httpService.get({
            url: API_URL.GET_CURRENCIES,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    changeProject(data, signal){
        return httpService.post({
			url: API_URL.CHANGE_ACCOUNT_PROJECT,
			signal,
			requestBody: data,
		}).then(({ data: { value } }) => {
            return value;
        })
    },

    authenticateQR(token, signal) {
		return httpService.get({
			url: API_URL.AUTHENTICATE_QR,
			signal,
			configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token
				},
			},
		}).then(({ data: { value } }) => {
            return value;
        });
	},

	authenticateToken(token, code, signal) {
		return httpService.post({
			url: API_URL.AUTHENTICATE_TOKEN,
			signal,
            configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token
				},
			},
			requestBody: { token: code },
		}).then(({ data: { value } }) => {
            return value;
        });
	},

    switchAccount(id) {
		return httpService.post({
			url: API_URL.SWITCH_ACCOUNT,
			requestBody: {
				id,
			},
		}).then(({ data: { value } }) => {
            return value;
        });
	},

	switchAccountBack() {
		return httpService.post({
			url: API_URL.SWITCH_BACK_ACCOUNT
		}).then(({ data: { value } }) => {
            return value;
        });
	},

    forgotPassword(data, signal){
        return httpService.post({
			url: API_URL.FORGOT_PASSWORD,
			signal,
			requestBody: data 
		}).then(({ data: { value, message } }) => {
            return {value, message};
        });
    },

    getPasswordSettings(token, signal){
        return httpService.get({
			url: API_URL.GET_ACCOUNT_PASSWORD_SETTINGS,
			signal,
            configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token
				},
			}, 
		}).then(({ data: { value } }) => {
            return value;
        });
    },

    setPassword(data, token, signal){
        return httpService.post({
			url: API_URL.SET_PASSWORD,
			signal,
			requestBody: data,
            configs: {
				headers: {
					[AUTHORIZATION]: "Bearer " + token
				},
			},  
		}).then(({ data: { value } }) => {
            return value;
        });
    },

    getResetPasswordSettings(token, signal){
        return httpService.get({
			url: API_URL.GET_ACCOUNT_RESET_PASSWORD_SETTINGS,
			signal,
            queryData: {
				token
			}, 
		}).then(({ data: { value } }) => {
            return value;
        });
    },

    resetPassword(data, signal){
        return httpService.post({
			url: API_URL.RESET_PASSWORD,
			signal,
			requestBody: data 
		}).then(({ data: { value } }) => {
            return value;
        });
    },

    verifyEmail(token, signal){
        return httpService.post({
			url: API_URL.VERIFY_EMAIL,
			signal,
			requestBody: {
                token
            } 
		}).then(({ data: { value } }) => {
            return value;
        });
    },

    register(data, signal){
        return httpService.post({
			url: API_URL.PROMO_REGISTER,
			signal,
			requestBody: data 
		}).then(({ data: { value, message } }) => {
            return { value, message };
        });
    },
}

export default accountService;