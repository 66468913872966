import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";
import { RESPONSE_CODES } from 'constants/errorCode.constants';

import { isResponseSuccess } from "utils/request";

import { setWalletSaveActionBefore, setWalletSaveActionFinished } from './wallet.action';
import { setAffiliateWalletsByCurrency } from '../affiliates/affiliates/affiliates.action';
//import { updateAffiliateWallets } from './affiliateWallet.action';
import { SET_WALLET_TRANSFER_SUCCESS , SET_WALLET_TRANSFER_RESULT } from "../../../actionTypes";

export const showTransferSuccess = show => ({
    type: SET_WALLET_TRANSFER_SUCCESS,
    payload: {show}
});

export const showTransferResult = (show, data) => ({
    type: SET_WALLET_TRANSFER_RESULT,
    payload: {show, data}
});

const handleTransferResponse = (data, dispatch) => {
    if (isResponseSuccess(data)) {
        const resp = data.value;
        if(resp){
            if(resp.status === RESPONSE_CODES.Success){
                dispatch(showTransferSuccess(true));

                const wallets = resp.wallets.map(wallet => ({
                    ownerType: wallet.ownerType,
                    currencyCode: wallet.currencyCode,
                    type: wallet.type,
                    userName: wallet.userName,
                    totalAmount: wallet.totalAmount,
                    id: wallet.id
                }))

                // dispatch(updateAffiliateWallets(wallets.map(w => ({
                //     id: w.id,
                //     userName: w.userName,
                //     currencyCode: w.currencyCode,
                //     type: w.type,
                //     amount: w.totalAmount
                // }))))

                dispatch(setAffiliateWalletsByCurrency(wallets));

            } 
            dispatch(showTransferResult(true, resp.wallets))
        }
    } 
}

export const affilaiteDepositToEarningBalance = data => {
    return dispatch => {
        dispatch(setWalletSaveActionBefore());
        return axios({
            url: ApiUrls.AFFILIATE_DEPOSIT_TO_EARNING_BALANCE,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            handleTransferResponse(data, dispatch);
            dispatch(setWalletSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setWalletSaveActionFinished());
        })
    }
}

export const affilaiteWithdrawFromEarningBalance = data => {
    return dispatch => {
        dispatch(setWalletSaveActionBefore());
        return axios({
            url: ApiUrls.AFFILIATE_WITHDRAW_FROM_EARNING_BALANCE,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            handleTransferResponse(data, dispatch);
            dispatch(setWalletSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setWalletSaveActionFinished());
        })
    }
}

export const rollbackTransaction = data => {
    return dispatch => {
        dispatch(setWalletSaveActionBefore());
        return axios({
            url: ApiUrls.WALLET_ROLLBACK_TRANSACTION,
            method: Methods.POST,
            data: {
                ...data
            }
        })
        .then(({ data }) => {
            handleTransferResponse(data, dispatch);
            dispatch(setWalletSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setWalletSaveActionFinished());
        })
    }
}