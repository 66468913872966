import React from "react";
import PropTypes from "prop-types";

import { Col, Row, Carousel } from "antd";

import useMarketingPieCharts from "../../hooks/useMarketingPieCharts";

import { isMobile } from "utils/common";

import { COUNT_CHART_TYPE } from "../../constants";

import { useTranslation } from "core/hooks/useTranslation";

import useFormat from "hooks/useFormat";
import Icon from "components/common/icon";

const availableTypes = [ 
    COUNT_CHART_TYPE.VIEW,
    COUNT_CHART_TYPE.VALID_CLICK,
    COUNT_CHART_TYPE.INVALID_CLICK,
    COUNT_CHART_TYPE.CR_TO_REG
]

const mapping = {
    [COUNT_CHART_TYPE.VIEW] : {
        title: "backoffice.dashboard.views",
        name: "visible",
    },

    [COUNT_CHART_TYPE.VALID_CLICK] : {
        title: "backoffice.dashboard.validClicks",
        name: "marketingSend",
    
    },

    [COUNT_CHART_TYPE.INVALID_CLICK] : {
        title: "backoffice.dashboard.invalidClicks",
        name: "sendDashed",
    },

    [COUNT_CHART_TYPE.CR_TO_REG] : {
        title: "backoffice.dashboard.crToReg",
        name: "profile",
    } 
}

const MarketingPieCharts = ({
    globalProjectId,
    filters,
    updateLastUpdatedDate
}) => {

    const { t } = useTranslation()
    const { formatNumber } = useFormat();

    const { data, isLoading } = useMarketingPieCharts(
        filters,
        globalProjectId,
        updateLastUpdatedDate
    );

    const renderCount = type => {
        let count = data[type] ?? 0;
        if(type === COUNT_CHART_TYPE.CR_TO_REG){
            const validCLickCount = data[COUNT_CHART_TYPE.VALID_CLICK] ?? 0;
            const registrationsCount = data[COUNT_CHART_TYPE.CR_TO_REG] ?? 0;
            const percent = validCLickCount !== 0 ? (registrationsCount / validCLickCount) * 100 : 0 ;
            count = Number(percent.toFixed(2))
        }

        const mappingData = mapping[type];
        
        return (
            <div className={'rt--flex rt--align-center rt--justify-between rt--pl-28 rt--statistic rt--statistic-' + (mappingData.name + ' ') + (isMobile() ? 'rt--mb-16 rt--pr-14' : 'rt--mb-24 rt--pr-34')}>

                <div className='rt--statistic-content rt--flex rt--flex-col rt--flex-equal'>                
                    <span className='rt--statistic-title rt--font-extra-bold rt--font-small'>
                        {
                            `${t(mappingData.title)}`
                        }
                    </span>
                    <span className='rt--statistic-count rt--font-extra-bold rt--font-bigest'
                        title={`${formatNumber(count)}`}
                        >
                        {
                            `${formatNumber(count)}`
                        }
                    </span>
                </div>

                <div className='rt--statistic-icon-background rt--flex rt--align-center rt--justify-center'>
                    <div className={"rt--statistic-icon rt--flex rt--align-center rt--justify-center"}>
                        <Icon className="rt--font-28" name={mappingData.name} />
                    </div>  
                </div>

            </div>
        )
    }

    return !isMobile() ? (
        <Row gutter={[16, 16]}>
            {
                availableTypes.map(type => (
                    <Col xl={6} lg={12} xs={12} key={type}>
                        { renderCount(type) }
                    </Col>
                ))
            }
        </Row>
    ) : (
        <Carousel infinite={true}>
            {   
                availableTypes.map(type => renderCount(type))
            }
        </Carousel>
    )
}

MarketingPieCharts.propTypes = {
    globalProjectId: PropTypes.string,
    filters: PropTypes.object,
    updateLastUpdatedDate: PropTypes.func
};

export default MarketingPieCharts;