import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import Select from 'components/common/select';
import Input from 'components/common/input';
import DateRangePicker from 'components/common/dateRangePicker';

import FiltersWrapper from "components/common/filters";

import useCurrencies from 'hooks/useCurrencies';

import { getAffiliates, setAffiliatesFilters } from "store/actions/portal/affiliates/affiliates/affiliates.action";
import { FILTER_USER_STATE } from "constants/filter.constants";
import { EMAIL_REGEX } from "constants/regex.constants";

import countries from "systemData/countries";

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

const FILTERS_FIELD_NAMES = {
    STATUS: "state",
    EMAIL: "email",
    COUNTRIES: "countries",
    CURRENCIES: "currencies",
    DATE: "date",
    LAST_LOGIN_DATE: "lastLogin",
}

/** Affiliates Page Filters Component */
const Filters = ({
    setAffiliatesFilters,
    getAffiliates,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [ currencies, getCurrencies ] = useCurrencies(false);

    return (
        <FiltersWrapper
            loadFn={getAffiliates}
            updateProps={[globalProjectId]}
            setFiltersFn={setAffiliatesFilters}
            filters={filters}
            datePickerFields={[
                { name: FILTERS_FIELD_NAMES.DATE, time: false },
                { name: FILTERS_FIELD_NAMES.LAST_LOGIN_DATE, time: false }
            ]}
            searchFieldName={["nameOrId", "date"]}
            onInit={() => {
                getCurrencies();
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.common.status')}
                        name={FILTERS_FIELD_NAMES.STATUS}
                    >
                        <Select
                            options={[
                                { value: FILTER_USER_STATE.ALL, text: t('backoffice.common.all') },
                                { value: FILTER_USER_STATE.IN_PROGRESS, text: t('backoffice.affiliates.pending') },
                                { value: FILTER_USER_STATE.ACTIVE, text: t('backoffice.affiliates.approved') },
                                { value: FILTER_USER_STATE.BLOCKED, text: t('backoffice.common.blocked') },
                                { value: FILTER_USER_STATE.DECLINED, text: t('backoffice.common.declined') }
                            ]}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.common.status')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.affiliates.email')}
                        className='rt--general-form-item'
                        name={FILTERS_FIELD_NAMES.EMAIL}
                        rules={[{ pattern: EMAIL_REGEX, message: t('backoffice.validation.emailFormat') }]}
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.email')}`}
                    >
                        <Input
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.email')}`}
                            maxLength={50}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.affiliates.country')}
                        name={FILTERS_FIELD_NAMES.COUNTRIES}
                    >
                        <Select
                            options={countries.map(c => ({ value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }))}
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.country')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            isMultiple={true}
                            search={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.affiliates.currency')}
                        name={FILTERS_FIELD_NAMES.CURRENCIES}
                    >
                        <Select
                            options={
                                currencies.map(item => (
                                    { value: item.code, text: item.code }
                                ))
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.currency')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            isMultiple={true}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label={t('backoffice.affiliates.lastLoginDate')}
                        name={FILTERS_FIELD_NAMES.LAST_LOGIN_DATE}
                        className='rt--form-item-without-margin'
                    >
                        <DateRangePicker
                            showTime={false}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get affiliates */
    getAffiliates: PropTypes.func,
    /** Redux action to set affiliates filters */
    setAffiliatesFilters: PropTypes.func,
    /** Redux state property, affiliates filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliates: () => {
            dispatch(getAffiliates())
        },
        setAffiliatesFilters: filters => {
            dispatch(setAffiliatesFilters(filters))
        },
    }
)

const mapStateToProps = state => {
    return {
        filters: state.affiliates.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);