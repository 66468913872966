const isMenuItemAvailable = (
	item,
	projectId,
	userRole,
	permUtils,
) => {
	if (item.userRole && !item.userRole.includes(userRole)) {
		return false;
	}

	if (item.permission) {
		if (Array.isArray(item.permission)) {
			if (!permUtils.some(...item.permission)) {
				return false;
			}
		} else {
			if (!permUtils.has(item.permission)) {
				return false;
			}
		}
	}

	if (item.disabled) return false;

	if (item.isProjectRequired && !projectId) {
		return false;
	}

	return true;
};

export default isMenuItemAvailable;