import React, { useEffect, useMemo } from "react"

import { useNavigate, Navigate } from "react-router-dom";

import usePermissions from "core/hooks/usePermission";
import { useTranslation } from "core/hooks/useTranslation";

import { globalProjectIdSelector, isAuthenticatedSelector, useAuthSelector, userRoleSelector } from "core/stores/authStore";

import ROUTES from "core/constants/routes/routes";

import { findFirstAvailableRoute, getAvailableRoutes } from "../helpers";

import { authorizedRoutes } from "../routes";


const UnknownRoute = () => {
    const { t } = useTranslation();

    const userRole = useAuthSelector(userRoleSelector);
    const isAuthenticated = useAuthSelector(isAuthenticatedSelector);
    const projectId = useAuthSelector(globalProjectIdSelector);

    const permissionUtils = usePermissions();

    const navigate = useNavigate();

    const pathToNavigate = useMemo(() => {
        if (isAuthenticated) {
            const availableRoutes = getAvailableRoutes({
                userRole,
                permissionUtils,
                routes: authorizedRoutes,
                projectId
            });

            return findFirstAvailableRoute(availableRoutes);
        }

        return ROUTES.LOGIN;
    }, [userRole, permissionUtils, projectId, isAuthenticated]);

    useEffect(() => {
        if (!pathToNavigate && window.location.pathname !== ROUTES.ROOT) {
            navigate(ROUTES.ROOT, {replace: true});
        }
    }, [pathToNavigate]);

    if (!pathToNavigate) {
        return <span className='rt--title rt--font-bold rt--text-center rt--font-bigest rt--no-perm'>{t("backoffice.common.noPermission")}</span>
    }

    return (
        <Navigate to={pathToNavigate} replace />
    );
}

export default UnknownRoute;