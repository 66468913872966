import axios from "core/lib/axios";

import { message } from 'antd';

import Methods from 'constants/methods.constants';
import ApiUrls from 'constants/api.constants';
import { isResponseSuccess } from "utils/request";

import {
    SET_REGISTRATION_FORM_ACTION_BEFORE,
    SET_REGISTRATION_FORM_ACTION_FINISH,
    SET_REGISTRATION_FORM,
    SET_REGISTRATION_ACTION_BEFORE,
    SET_REGISTRATION_ACTION_FINISH,
    SET_REGISTRATION_AVAILABLE_CURRENCIES,
    SET_REGISTRATION_AVAILABLE_PAYMENTS,
    SET_REGISTRATION_AVAILABLE_CHANNELS,
    SET_REGISTRATION_PASSWORD_SETTINGS,
    SET_REGISTRATION_PAYMENT_DETAILS
} from '../../actionTypes';


const setRegistrationFormActionBefore = () => ({
    type: SET_REGISTRATION_FORM_ACTION_BEFORE
})

const setRegistrationFormActionFinished = () => ({
    type: SET_REGISTRATION_FORM_ACTION_FINISH
})

const setRegistrationActionBefore = () => ({
    type: SET_REGISTRATION_ACTION_BEFORE
})

const setRegistrationActionFinished = () => ({
    type: SET_REGISTRATION_ACTION_FINISH
})

const setRegistrationForm = registrationForm => ({
    type: SET_REGISTRATION_FORM,
    payload: { registrationForm }
})

export const getRegistrationForm = id => {
    return dispatch => {
        dispatch(setRegistrationFormActionBefore());

        return axios({
            url: ApiUrls.GET_PROMO_REGISTRATION_FORM,
            method: Methods.GET,
            params: { id }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                dispatch(setRegistrationForm(data.value));
            } 
            dispatch(setRegistrationFormActionFinished());
        })
        .catch((ex) => {
            dispatch(setRegistrationFormActionFinished());
        })
    }
}

const setAvailableCurrencies = currencies => ({
    type: SET_REGISTRATION_AVAILABLE_CURRENCIES,
    payload: { currencies }
})

export const getAvailableCurrencies = id => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROMO_REGISTRATION_AVAILABLE_CURRENCIES,
            method: Methods.GET,
            params: { id }
        })
        .then(({ data }) => {
            dispatch(setAvailableCurrencies(data.value)); 
        })
        .catch((ex) => {
        })
    }
}

const setAvailablePayments = payments => ({
    type: SET_REGISTRATION_AVAILABLE_PAYMENTS,
    payload: { payments }
})

export const getAvailablePayments = (id, currencyCode) => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROMO_REGISTRATION_AVAILABLE_PAYMENTS,
            method: Methods.GET,
            params: { id, currencyCode }
        })
        .then(({ data }) => {
            dispatch(setAvailablePayments(data.value));
        })
        .catch((ex) => {
        })
    }
}

const setPaymentDetails = paymentDetails => ({
    type: SET_REGISTRATION_PAYMENT_DETAILS,
    payload: { paymentDetails }
})

export const getPaymentDetails = ( id, paymentId ) => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROMO_PAYMENT_DETAILS,
            method: Methods.GET,
            params: { id, paymentId }
        })
        .then(({ data }) => {
            dispatch(setPaymentDetails(data.value));
        })
        .catch((ex) => {
        })
    }
}

const setAvailableChannels = channels => ({
    type: SET_REGISTRATION_AVAILABLE_CHANNELS,
    payload: { channels }
})

export const getAvailableChannels = id => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROMO_CHANNELS,
            method: Methods.GET,
            params: { id }
        })
        .then(({ data }) => {
            dispatch(setAvailableChannels(data.value));
        })
        .catch((ex) => {
        })
    }
}

const setPasswordSettings = passwordSettings => ({
    type: SET_REGISTRATION_PASSWORD_SETTINGS,
    payload: { passwordSettings }
})

export const getPasswordSettings = id => {
    return dispatch => {
        return axios({
            url: ApiUrls.GET_PROMO_PASSWORD_SETTINGS,
            method: Methods.GET,
            params: { id }
        })
        .then(({ data }) => {
            dispatch(setPasswordSettings(data.value));
        })
        .catch((ex) => {
        })
    }
}

export const register = (data, token, languageCode, cb) => {
    return dispatch => {
        dispatch(setRegistrationActionBefore());
        return axios({
            url: ApiUrls.PROMO_REGISTER,
            method: Methods.POST,
            data: { 
                ... data,
                token,
                languageCode
            }
        })
        .then(({ data }) => {
            dispatch(setRegistrationActionFinished());
            if(isResponseSuccess(data)) {
                message.success(data.message);
                cb && cb(data.value);
            } else {
                console.log(data)
            }
        })
        .catch(() => {
            dispatch(setRegistrationActionFinished());
        })
    }
}
