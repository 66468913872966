import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin, Switch } from 'antd';

import Select from "components/common/select";
import Input from 'components/common/input';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";

import useAutosuggestion from "hooks/useAutosuggestion";

import { ADDRESS_REGEX} from "constants/regex.constants";

import { getAffiliateAdditionalInfo, saveAffiliateAdditionalInfo } from "store/actions/portal/affiliates/affiliates/general.action";

import { additionalInfoType } from "types/affiliate/generalInfo.type";
import TextAreaInput from 'components/common/textAreaInput';

import { flagsToBinary, binaryToFlags } from 'utils/common';
import { isFormChanged } from "utils/form";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS } from 'constants/affiliate.constants';
import { USER_ROLE, USER_STATE } from "constants/user.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';


const FORM_FIELDS_NAMES = {
    ASSIGNED_MANAGER: "managerId",
    TARGET_MARKET: "targetMarket",
    REFERRED_BY: "referralUserName",
    ADDITIONAL_INFORMATION: "additionalInfo",
    NEWSLETTER_CONFIGURATION: "receiveNewsletter"
};

/** Affiliate Edit Page General Info Additional SubTab Component */
const Additional = ({
    getAffiliateAdditionalInfo,
    saveAffiliateAdditionalInfo,
    isSaving,
    isLoading,
    additionalInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const routeParams = useParams();

    const permissionUtils = usePermissions();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const affiliateManagerState = useMemo(() => {
        return flagsToBinary(Object.values(USER_STATE).filter(
            (state) => state === USER_STATE.ACTIVE || state === USER_STATE.LOCKED_2_FA || state === USER_STATE.LOCKED_LOGIN
        ));
    }, [flagsToBinary, USER_STATE]);

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : routeParams.id;

    const canEdit = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, PERMISSION_ACTION.MODIFY ) && affiliateId !== userInfo.id
    
    const canAssignManager = canEdit && role === USER_ROLE.ADMIN;

    const [ affiliateManagers ] = canAssignManager ? useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATE_MANAGERS, 
        actionParams: { state: affiliateManagerState }
    }) : [ [] ];

    const affiliateManagerOptions = useMemo(() => {
        return [
            { value: null, text: t("backoffice.common.none") },
            ...affiliateManagers.map(({ name, id }) => ({ text: name, value: id }))
        ];
    }, [affiliateManagers]);

    const targetMarketOptions = useMemo(() => {
        return Object.keys(TARGET_MARKET).map((key) => (
            { value: TARGET_MARKET[key], text: t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[TARGET_MARKET[key]]}`) }
        ))
    }, []);

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf Additional
   */
    const formChanged = formValues => {
        return isFormChanged({ ...formValues, id: affiliateId }, { ...additionalInfo })
    }


    /** Fires when form submitted
       * @function
       * @memberOf Additional
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                console.log(data);
                saveAffiliateAdditionalInfo({
                    ...data,
                    id: affiliateId,
                    [FORM_FIELDS_NAMES.ASSIGNED_MANAGER]: canAssignManager ? data[FORM_FIELDS_NAMES.ASSIGNED_MANAGER] : additionalInfo.managerId,
                    [FORM_FIELDS_NAMES.TARGET_MARKET]: data[FORM_FIELDS_NAMES.TARGET_MARKET]?.length > 0 ? flagsToBinary(data[FORM_FIELDS_NAMES.TARGET_MARKET]) : null
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }


    /** Get field max length
       * @function
       * @param {object} - control
       * @returns {number}
       * @memberOf Additional
   */
    const getFieldMaxLength = name => {
        switch (name) {
            case FORM_FIELDS_NAMES.ASSIGNED_MANAGER:
                return 30;
            case FORM_FIELDS_NAMES.REFERRED_BY:
            case FORM_FIELDS_NAMES.NEWSLETTER_CONFIGURATION:
                return 18;
            case FORM_FIELDS_NAMES.STREET:
                return 102;
            case FORM_FIELDS_NAMES.ADDITIONAL_INFORMATION:
                return 400;
            default:
                return undefined;
        }
    }

    /** Get rules for control 
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf Additional
   */
    const getRulesForField = name => {
        const rules = [];

        switch (name) {
            case FORM_FIELDS_NAMES.ADDITIONAL_INFORMATION:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                break;
            case FORM_FIELDS_NAMES.STREET:
                rules.push({ max: getFieldMaxLength(name), message: t('backoffice.validation.fieldInvalid') });
                rules.push({ pattern: ADDRESS_REGEX, message: t('backoffice.validation.fieldInvalid') });
                break;
            default:
                break;
        }

        return rules;
    }

    /** Load Affiliate general info */
    useEffect(() => {
        getAffiliateAdditionalInfo(affiliateId);
    }, []);

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...additionalInfo,
            [FORM_FIELDS_NAMES.ASSIGNED_MANAGER]: !canAssignManager ? additionalInfo?.["managerUserName"] : additionalInfo?.[FORM_FIELDS_NAMES.ASSIGNED_MANAGER],
            [FORM_FIELDS_NAMES.TARGET_MARKET]: additionalInfo?.[FORM_FIELDS_NAMES.TARGET_MARKET] ? binaryToFlags(Object.values(TARGET_MARKET), additionalInfo[FORM_FIELDS_NAMES.TARGET_MARKET]) : undefined
        })
    }, [additionalInfo]);

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: true,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >

            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{}}
                    onValuesChange={(_, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.assignedManager')}
                                name={FORM_FIELDS_NAMES.ASSIGNED_MANAGER}
                                className={(!canAssignManager ? " rt--form-item-disabled" : "")}
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.assignedManager')}`}
                                    disabled={!canAssignManager}
                                    search={true}
                                    options={affiliateManagerOptions}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={t('backoffice.affiliates.targetMarket')}
                                name={FORM_FIELDS_NAMES.TARGET_MARKET}
                                className={(!canEdit ? " rt--form-item-disabled" : "")}
                            >
                                <Select
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.affiliates.targetMarket')}`}
                                    disabled={!canEdit}
                                    search={true}
                                    isMultiple={true}
                                    options={targetMarketOptions}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                />
                            </Form.Item>
                        </Col>
                        {
                            additionalInfo[FORM_FIELDS_NAMES.REFERRED_BY] && (
                                <Col xs={24} sm={12} xl={6}>
                                    <Form.Item
                                        label={`${t('backoffice.affiliates.referredBy')}`}
                                        name={FORM_FIELDS_NAMES.REFERRED_BY}
                                        className="rt--general-form-item rt--form-item-disabled"
                                    >
                                        <Input
                                            maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.REFERRED_BY)}
                                            disabled={true}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }

                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.affiliates.additionalInformation')}`}
                                name={FORM_FIELDS_NAMES.ADDITIONAL_INFORMATION}
                                rules={getRulesForField(FORM_FIELDS_NAMES.ADDITIONAL_INFORMATION)}
                                validateFirst
                                className={"rt--general-form-item" + (!canEdit ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.additionalInformation')}`}
                            >
                                <TextAreaInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.affiliates.additionalInformation')}`}
                                    maxLength={getFieldMaxLength(FORM_FIELDS_NAMES.ADDITIONAL_INFORMATION)}
                                    disabled={!canEdit}
                                    rows={4}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name={FORM_FIELDS_NAMES.NEWSLETTER_CONFIGURATION}
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!canEdit}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.affiliates.newsLetterConfiguration')}</label>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

Additional.propTypes = {
    /** Redux action to get affiliate General info */
    getAffiliateAdditionalInfo: PropTypes.func,
    /** Redux action to save affiliate General info */
    saveAffiliateAdditionalInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the general info of current editing affiliate  */
    additionalInfo: additionalInfoType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateAdditionalInfo: id => {
            dispatch(getAffiliateAdditionalInfo(id));
        },
        saveAffiliateAdditionalInfo: data => {
            dispatch(saveAffiliateAdditionalInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        additionalInfo: state.affiliates.edit.general.additional,
        isSaving: state.affiliates.isSaving,
        isLoading: state.affiliates.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Additional)