import React, { useCallback, useState, useEffect } from "react";

import { Sider } from 'core/ui-kit/layout';
import Icon from "core/ui-kit/icon";
import Button from "core/ui-kit/button";

import SidebarLogo from "./sidebarLogo";
import Menu from "./menu";
import Clock from "./clock";

import { useTranslation } from "core/hooks/useTranslation";

import useDevice from "core/hooks/useDevice";

import { authStore } from "core/stores/authStore";

import { COLLAPSED_SIDEBAR_WIDTH, SIDEBAR_WIDTH } from "../constants";

const Sidebar = () => {

    const { t } = useTranslation();

    const { isMobile } = useDevice();

    const [collapsed, setCollapsed] = useState(false);

    const isSidebarCollapsed = !isMobile && collapsed;

    const handleLogout = () => {
        authStore.logout();
    }

    const onWindowResize = useCallback(() => {
		setCollapsed(window.innerWidth < 1440);
	}, [setCollapsed]);

	useEffect(() => {
		if (!isMobile) {
			onWindowResize();
			window.addEventListener("resize", onWindowResize);

			return () => {
				window.removeEventListener("resize", onWindowResize);
			};
		}
	}, []);

    return (
        <Sider
			className="rt--sidebar"
			width={isMobile ? "100vw" : SIDEBAR_WIDTH}
			collapsedWidth={COLLAPSED_SIDEBAR_WIDTH}
			collapsed={isSidebarCollapsed}
		>
            <div className='rt--sidebar-content rt--pt-24 rt--flex rt--flex-col'>
                {
                    isMobile && (
                        <div
                            className='rt--sidebar-content-close'
                            onClick={() => document.body.classList.remove("rt--body-open-sidebar")}
                        >
                            <Icon name='close' />
                        </div>
                    )
                }
                <SidebarLogo 
                    isCollapsed={isSidebarCollapsed}
                />

                <Menu 
                    isCollapsed={isSidebarCollapsed}
                />
            
                {
                    isMobile && (
                        <div className='rt--sidebar-content-logout rt--pl-16 rt--pr-16 rt--mt-24'>
                            <div className='rt--sidebar-content-logout-separator rt--mb-32' />
                            <Button
                                className='rt--sidebar-content-logout-button'
                                onClick={handleLogout}
                            >
                                <Icon name='logout' />
                                <span className='rt--title rt--font-normal rt--font-regular'>{t('backoffice.common.logout')}</span>
                            </Button>
                        </div>
                    )
                }

                <div className='rt--sidebar-content-footer rt--pl-24 rt--pr-16 rt--flex rt--justify-between rt--align-center rt--pb-26'>
                    <Clock isCollapsed={isSidebarCollapsed} />
                    {
                        !isMobile && (
                            <div className='rt--sidebar-content-footer-collapse' onClick={() => setCollapsed(!isSidebarCollapsed)}>
                                <Icon name={isSidebarCollapsed ? 'expand' : 'collapse'} size={20}/>
                            </div>
                        )
                    }

                </div>
            </div>
        </Sider>
    )
}

export default Sidebar;