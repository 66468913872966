import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import classNames from "core/helpers/common/classNames";

import { useGlobalSelector, languagesSelector } from "core/stores/globalStore";

import useDevice from "core/hooks/useDevice";

import AuthSidebar from "./sidebar";
import LanguagesDropdown from "./languagesDropdown";

import accountService from "core/services/apiServices/accountService";

import { LOGO_TYPE } from "core/constants/common/logo";

import { mapLogosData } from "./helpers";

const AuthLayout = () => {

    const [ logos, setLogos ] = useState({})

    const languages = useGlobalSelector(languagesSelector);

    const { isMobile } = useDevice()

    const headerClassNames = classNames(
		"rt--auth-header rt--flex rt--align-center",
		isMobile ? "rt--justify-between" : "rt--justify-end",
	);

    const contentClassNames = classNames(
		"rt--auth-content rt--flex rt--align-center rt--justify-center",
		!isMobile && "rt--flex-equal",
	);

    /** Load logos */
    useEffect(() => {
        accountService.getApplicationLogos()
            .then(value => {
                setLogos(mapLogosData(value))
            })
    }, [])

    return (
        <div className="rt--auth rt--flex">
            <div className={headerClassNames}>
                {
                    isMobile ? <img src={`${import.meta.env.SYSTEM_CDN_URL}/${logos[LOGO_TYPE.COLLAPSED_LOGO]}`}/> : null
                }
                <LanguagesDropdown 
                    languages={languages}
                />
            </div>
            {
                !isMobile && <AuthSidebar logos={logos} />
            }
            <div className={contentClassNames}>
                <Outlet />
            </div>
        </div>
    )
}

export default AuthLayout;