import React, { useEffect, useState, useMemo, Fragment, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation } from "react-router-dom";

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Form, Row } from 'antd';

import FormItems from './formItems';

import {
    getRegistrationForm,
    getAvailableCurrencies,
    getAvailablePayments,
    getAvailableChannels,
    getPasswordSettings,
    getPaymentDetails,
    register
} from "store/actions/auth/register.action";

import { COMPANY_CONTROL_RULE, COMPANY_CONTROL_TYPE } from "constants/company.constants";

import { flagsToBinary, toLowerCaseFirstLetter, toUpperCaseFirstLetter } from "utils/common";
import { validateByRegexp } from "utils/validate"
import { validatePassword } from "utils/password";

import registrationFormType from 'types/project/registrationForm.type';
import systemPaymentType from 'types/systemPayments/systemPayment.type';
import passwordSettingsType from 'types/company/passwordSettings.type';
import paymentDetailsType from 'types/systemPayments/paymentDetails.type';

import countries from "systemData/countries";

import { REG_FORM_CONTROL_NAMES } from 'constants/affiliate.constants';
import { TARGET_MARKET, TARGET_MARKETS_TT_KEYS, CONTACT_CHANNEL_TYPES } from "constants/affiliate.constants";

const PAYMENT_DETAILS_PREFIX = "paymentMethodDetails";

const RegisterForm = ({
    enableRecaptcha = false,
    isSaving,
    formInstaceRef,
    id,
    onRegisterSuccess,
    registerAction,
    getFieldLabel,
    getFieldPopupContainer,
    getScrollArea = Function.prototype,
    registrationForm,
    getRegistrationForm,
    getAvailableCurrencies,
    availableCurrencies,
    getAvailablePayments,
    availablePayments,
    getAvailableChannels,
    availableChannels,
    paymentDetails,
    passwordSettings,
    getPasswordSettings,
    getPaymentDetails,
}) => {
    const { t, locale } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha(enableRecaptcha);
    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { search} = useLocation();    
    const referralId = (new URLSearchParams(search)).get("referralId");

    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [selectedContactChannel, setSelectedContactChannel] = useState(null);

    const fieldsHandlers = useMemo(() => {
        return { setSelectedCurrency, setSelectedPayment, setSelectedContactChannel };
    }, [setSelectedCurrency, setSelectedPayment, setSelectedContactChannel]);

    const activeRegistrationForm = useMemo(() => {
        let fields = registrationForm.filter(control => control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN);
        if (selectedContactChannel) {
            const contactChannelFieldIndex = fields.findIndex(field => field.name === REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL);
            const contactChannelData = availableChannels[selectedContactChannel];
            fields.splice(contactChannelFieldIndex + 1, 0, {
                name: REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL_NAME,
                selectedRule: COMPANY_CONTROL_RULE.REQUIRED,
                controlType: COMPANY_CONTROL_TYPE.INPUT,
                regExp: contactChannelData.regExp
            })
        }
        return fields;
    }, [registrationForm, availableChannels, selectedContactChannel])

    const activePaymentDetailsForm = useMemo(() => paymentDetails?.formControls.filter(field => field.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN) ?? [], [paymentDetails])

    const getPaymentFieldTranslation = control => {
        const key = toLowerCaseFirstLetter(control.name);
        const translations = paymentDetails?.translations ?? [];
        const translation = translations.find(t => t.key === key)?.text ?? "";

        return translation ? translation : control.name
    };

    const isFieldDisabled = (name) => {
        switch (name) {
            case REG_FORM_CONTROL_NAMES.PAYMENT_METHOD:
                return name === REG_FORM_CONTROL_NAMES.PAYMENT_METHOD && !selectedCurrency;
            default:
                return false;
        }
    };

    const getFieldName = (control, prefix) => prefix ? [prefix, toLowerCaseFirstLetter(control.name)] : toLowerCaseFirstLetter(control.name);

    const getFieldClassName = (control) => {
        switch (control.name) {
            case REG_FORM_CONTROL_NAMES.PAYMENT_METHOD:
                return control.name === REG_FORM_CONTROL_NAMES.PAYMENT_METHOD && !selectedCurrency ? "rt--form-item-disabled" : "";
            default:
                return "";
        }
    };

    /** Get rules for control 
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf RegisterComponent
   */
    const getFieldRules = control => {
        const rules = [];

        if (control.selectedRule === COMPANY_CONTROL_RULE.REQUIRED) {
            if (
                control.controlType === COMPANY_CONTROL_TYPE.SELECT
            ) {
                rules.push({ required: true, message: t('backoffice.validation.fieldRequired') })
            } else if (
                control.controlType === COMPANY_CONTROL_TYPE.INPUT ||
                control.controlType === COMPANY_CONTROL_TYPE.PASSWORD
            ) {
                rules.push({ required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') });

                if(control.name === REG_FORM_CONTROL_NAMES.CONFIRM_PASSWORD) {
                    rules.push(({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (value !== getFieldValue(getFieldName({ name: REG_FORM_CONTROL_NAMES.PASSWORD }))) {
                                return Promise.reject(t('backoffice.validation.passwordsDoNotMatch'))
                            }
                            return Promise.resolve();
                        }
                    }));
                }
            } else if (control.controlType === COMPANY_CONTROL_TYPE.CHECKBOX) {
                rules.push(
                    () => ({
                        validator(_, value) {
                            if (!value) {
                                return Promise.reject(t('backoffice.validation.fieldRequired'))
                            } else {
                                return Promise.resolve()
                            }
                        }
                    })
                )
            }
        }

        if (control.name === REG_FORM_CONTROL_NAMES.PASSWORD) {
            rules.push(
                () => ({
                    validator(_, value) {
                        return validatePassword(value, passwordSettings, t);
                    }
                })
            )
        }

        if (control.regExp) {
            rules.push(
                () => ({
                    validator(_, value) {
                        return validateByRegexp(value, control.regExp, t);
                    }
                })
            )
        }

        return rules;
    }

    /** Get data for select control 
       * @function
       * @param {object} - control
       * @returns {array}
       * @memberOf RegisterComponent
   */
    const getSelectFieldData = control => {
        const data = {
            [REG_FORM_CONTROL_NAMES.COUNTRY]: {
                items: countries.map(c => ({
                    value: c.iso2,
                    text: t(`backoffice.countries.${c.iso2}`)
                })),
                showSearch: true,
                isMultiple: false
            },
            [REG_FORM_CONTROL_NAMES.CURRENCY_CODE]: {
                items: Object.keys(availableCurrencies).map(cur => ({
                    value: cur.toUpperCase(),
                    text: `${availableCurrencies[cur]} - ${cur.toUpperCase()}`
                })),
                showSearch: false,
                isMultiple: false
            },
            [REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL]: {
                items: Object.keys(availableChannels).map(channel => ({
                    value: channel,
                    text: toUpperCaseFirstLetter(CONTACT_CHANNEL_TYPES[channel])
                })),
                showSearch: false,
                isMultiple: false
            },
            [REG_FORM_CONTROL_NAMES.TARGET_MARKET]: {
                items: Object.keys(TARGET_MARKET).map((key) => (
                    { value: TARGET_MARKET[key], text: t(`backoffice.affiliates.${TARGET_MARKETS_TT_KEYS[TARGET_MARKET[key]]}`) }
                )),
                showSearch: false,
                isMultiple: true
            },
            [REG_FORM_CONTROL_NAMES.PAYMENT_METHOD]: {
                items: availablePayments.map(payment => ({
                    value: payment.id,
                    text: payment.name
                })),
                showSearch: true,
                isMultiple: false
            }
        };
        return data[control.name];
    }

    /** Fires when form submitted
       * @function
       * @memberOf RegisterComponent
   */
    const handleForm = (localData = {}) => {
        validateFields()
            .then((data) => {
                const dataToSend = { ...data, ...localData };
                dataToSend.id = id;

                if(referralId){
                    dataToSend.referralId = referralId;
                }

                const contactChannelTypeFieldName = toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL);
                const contactChannelNameFieldName = toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL_NAME);
                const targetMarketFieldName = toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.TARGET_MARKET);

                if (dataToSend[contactChannelTypeFieldName]) {
                    dataToSend["contactChannelType"] = dataToSend[contactChannelTypeFieldName];
                    dataToSend["contactChannel"] = dataToSend[contactChannelNameFieldName];
                    delete dataToSend[contactChannelNameFieldName];
                }

                dataToSend[targetMarketFieldName] = dataToSend[targetMarketFieldName]?.length > 0 ? flagsToBinary(dataToSend[targetMarketFieldName]) : null;

                if (import.meta.env.MODE === "development" || !enableRecaptcha) {
                    registerAction(dataToSend, "34f530b1728d4b28a5d1e39bb92be946", locale, onRegisterSuccess);
                } else {
                    executeRecaptcha()
                        .then(token => {
                            registerAction(dataToSend, token, locale, onRegisterSuccess);
                        })
                }
            }).catch(ex => {
                console.log(ex)
            })
    }

    useImperativeHandle(formInstaceRef, () => ({ handleForm, isSaving }));

    //#region --------------------------------------- SIDE EFFECTS ----------------------------------------//

    /** Load registration form */
    useEffect(() => {
        getRegistrationForm(id);
    }, [])

    /** Load contact channels */
    useEffect(() => {
        getAvailableChannels(id)
    }, [])

    /** Load password settings */
    useEffect(() => {
        getPasswordSettings(id)
    }, [])

    /** Load Currencies */
    useEffect(() => {
        if (
            registrationForm.some(control => control.name === REG_FORM_CONTROL_NAMES.CURRENCY_CODE && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN)
        ) {
            getAvailableCurrencies(id);
        }
    }, [registrationForm])

    /** Load Payments */
    useEffect(() => {
        if (selectedCurrency) {
            getAvailablePayments(id, selectedCurrency);
        }
        setFieldsValue({ [toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.PAYMENT_METHOD)]: null });
        setSelectedPayment(null);
    }, [selectedCurrency])

    /** Load Payments */
    useEffect(() => {
        if (selectedPayment) {
            getPaymentDetails(id, selectedPayment);
        }
    }, [selectedPayment])

    /** If only 1 currency available, set it by default */
    useEffect(() => {
        if (
            registrationForm.some(control => control.name === REG_FORM_CONTROL_NAMES.CURRENCY_CODE && control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN)
        ) {
            const itemsCount = Object.keys(availableCurrencies).length;
            if (itemsCount === 1) {
                const currencyCode = Object.keys(availableCurrencies)[0]
                setFieldsValue({
                    currencyCode: currencyCode.toUpperCase()
                })
            }
        }
    }, [registrationForm, availableCurrencies])

    /** Set initial values for input fields */
    useEffect(() => {
        const values = {};
        registrationForm.forEach(control => {
            if (control.selectedRule !== COMPANY_CONTROL_RULE.HIDDEN && control.controlType === COMPANY_CONTROL_TYPE.INPUT) {
                values[toLowerCaseFirstLetter(control.name)] = null;
            }
        })
        setFieldsValue(values);
    }, [registrationForm]);

    /** Clear Contact Channel Details on Contact Channel Change */
    useEffect(() => {
        if(selectedContactChannel) {
            setFieldsValue({
                [toLowerCaseFirstLetter(REG_FORM_CONTROL_NAMES.CONTACT_CHANNEL_NAME)]: null
            });
        }
    }, [selectedContactChannel]);

    /** Clear Payment Method Details on Currency/Payment Method Change */
    useEffect(() => {
        const emptyPaymentDetailsFormData = () => {
            return activePaymentDetailsForm.reduce((acc, paymentDetailsControl) => {
                acc[toLowerCaseFirstLetter(paymentDetailsControl.name)] = null;
    
                return acc;
            }, {});
        };

        setFieldsValue({
            [PAYMENT_DETAILS_PREFIX]: emptyPaymentDetailsFormData()
        });
    }, [selectedPayment, selectedCurrency]);

    /** Scroll To bottom when payment details loaded */
    useEffect(() => {
        if (selectedPayment !== null && paymentDetails) {
            setTimeout(() => {
                const scrollAreaElement = getScrollArea();

                scrollAreaElement && scrollAreaElement.scroll({ top: scrollAreaElement.scrollHeight, behavior: 'smooth' });
            }, 200)
        }
    }, [selectedPayment, paymentDetails])

    //#endregion

    return (
        <Form
            className='rt--form'
            form={formInstance}
            requiredMark={false}
            layout="vertical"
            scrollToFirstError={{
                boundary: getScrollArea(),
                skipOverflowHiddenElements: true
            }}
        >
            <>
                <Row gutter={[16, 0]}>
                    <FormItems
                        items={activeRegistrationForm.filter(control => control.controlType !== COMPANY_CONTROL_TYPE.CHECKBOX)}
                        formInstance={formInstance}
                        isFieldDisabled={isFieldDisabled}
                        getFieldPopupContainer={getFieldPopupContainer}
                        getFieldLabel={getFieldLabel}
                        getFieldName={getFieldName}
                        getFieldClassName={getFieldClassName}
                        getFieldRules={getFieldRules}
                        getSelectFieldData={getSelectFieldData}
                        handlers={fieldsHandlers}
                    />
                </Row>

                {
                    selectedPayment && activePaymentDetailsForm.length > 0 && (
                        <Fragment>
                            <div className='rt--mt-16 rt--mb-16'>
                                <span className="rt--title rt--font-big rt--font-regular">{t('backoffice.authentication.paymentDetails')}</span>
                            </div>
                            <Row gutter={[16, 0]}>
                                <FormItems
                                    items={activePaymentDetailsForm}
                                    formInstance={formInstance}
                                    prefix={PAYMENT_DETAILS_PREFIX}
                                    isFieldDisabled={isFieldDisabled}
                                    getFieldPopupContainer={getFieldPopupContainer}
                                    getSelectFieldData={getSelectFieldData}
                                    getFieldLabel={getPaymentFieldTranslation}
                                    getFieldName={getFieldName}
                                    getFieldClassName={getFieldClassName}
                                    getFieldRules={getFieldRules}
                                    handlers={fieldsHandlers}
                                />
                            </Row>
                        </Fragment>
                    )
                }

                <Row gutter={[16, 0]}>
                    <FormItems
                        items={activeRegistrationForm.filter(control => control.controlType === COMPANY_CONTROL_TYPE.CHECKBOX)}
                        formInstance={formInstance}
                        isFieldDisabled={isFieldDisabled}
                        getFieldPopupContainer={getFieldPopupContainer}
                        getFieldLabel={getFieldLabel}
                        getFieldName={getFieldName}
                        getFieldClassName={getFieldClassName}
                        getFieldRules={getFieldRules}
                        getSelectFieldData={getSelectFieldData}
                        handlers={fieldsHandlers}
                    />
                </Row>
            </>
        </Form>
    )
};

RegisterForm.propTypes = {
    /** React Property, get Form Field label */
    getFieldLabel: PropTypes.func,
    /** React Property, Field Popup Container */
    getFieldPopupContainer: PropTypes.func,
    /** React Property, Container where will be scrolled to the desired place */
    getScrollArea: PropTypes.func,
    /** React Property, Redux action */
    registerAction: PropTypes.func,
    /** React Property, given ref from Parent Component */
    formInstaceRef: PropTypes.object,
    /** React Property, given ProjectId */
    id: PropTypes.string,
    /** React Property, enable/disable Recaptcha */
    enableRecaptcha: PropTypes.bool,
    /** Redux state property, is true when loading registration form */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when registering */
    isSaving: PropTypes.bool,
    /** Redux state property, registration form */
    registrationForm: PropTypes.arrayOf(registrationFormType),
    /** Redux action to get registration form */
    getRegistrationForm: PropTypes.func,
    /** Redux action to get available currencies */
    getAvailableCurrencies: PropTypes.func,
    /** Redux action to get available payments */
    getAvailablePayments: PropTypes.func,
    /** Redux action to get available channels */
    getAvailableChannels: PropTypes.func,
    /** Redux action to get available channels */
    getPasswordSettings: PropTypes.func,
    /** Redux action to get payment details */
    getPaymentDetails: PropTypes.func,
    /** Redux state property, available currencies */
    availableCurrencies: PropTypes.object,
    /** Redux state property, available payments */
    availablePayments: PropTypes.arrayOf(systemPaymentType),
    /** Redux state property, available channels */
    availableChannels: PropTypes.object,
    /** Redux state property, password settings */
    passwordSettings: passwordSettingsType,
    /** Redux state property, payment details */
    paymentDetails: paymentDetailsType,
    /** Redux action to register */
    register: PropTypes.func,
    /** React Property, will be called on Register Success */
    onRegisterSuccess: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getRegistrationForm: id => {
            dispatch(getRegistrationForm(id));
        },

        getAvailableCurrencies: id => {
            dispatch(getAvailableCurrencies(id));
        },

        getAvailablePayments: (id, currencyCode) => {
            dispatch(getAvailablePayments(id, currencyCode));
        },

        getAvailableChannels: id => {
            dispatch(getAvailableChannels(id));
        },

        getPasswordSettings: id => {
            dispatch(getPasswordSettings(id));
        },

        getPaymentDetails: (id, paymentId) => {
            dispatch(getPaymentDetails(id, paymentId));
        },

        register: (data, token, cb) => {
            dispatch(register(data, token, cb));
        },
    }
)

const mapStateToProps = state => {
    return {
        registrationForm: state.register.registrationForm,
        isLoading: state.register.isLoading,
        isSaving: state.register.isSaving,
        availableCurrencies: state.register.availableCurrencies,
        availablePayments: state.register.availablePayments,
        availableChannels: state.register.availableChannels,
        passwordSettings: state.register.passwordSettings,
        paymentDetails: state.register.paymentDetails,
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    RegisterForm
);