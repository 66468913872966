import RESPONSE_CODES from "../api/responseCodes";

export const USER_STATE = {
	IN_PROGRESS: 1 << 0,
	ACTIVE: 1 << 1,
	BLOCKED: 1 << 2,
	EXPIRED: 1 << 3,
	DELETED: 1 << 4,
	LOCKED2FA: 1 << 5,
	LOCKEDLOGIN: 1 << 6,
};

export const TOKEN_TYPE = {
	NONE: "None",
	QR: "QR",
	TOKEN: "Token",
	PASSWORD_EXPIRED: "PasswordExpired",
};

export const USER_ROLE = {
    ADMIN: "Admin",
    AFFILIATE: "Affiliate",
    AFFILIATE_MANAGER: "AffiliateManager",
    ACCESS_MANAGER: "AccessManager"
}

export const USER_TYPE = {
    ADMIN: 1 << 0,
    AFFILIATE_MANAGER: 1 << 1,
    AFFILIATE: 1 << 2,
    PLAYER: 1 << 3,
    ACCESS_MANAGER: 1 << 4
}

export const ERROR_CODES_FOR_LOGIN = [
    RESPONSE_CODES.AccountLoginLocked,
    RESPONSE_CODES.AccountLoginTemporaryLocked,
    RESPONSE_CODES.NotApproved,
    RESPONSE_CODES.Declined,
    RESPONSE_CODES.Blocked
]