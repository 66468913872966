import ROUTES from "core/constants/routes/routes";

import AffiliatesComponent from "pages/affiliates/affiliates/affiliates.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "core/constants/permission";

import { USER_ROLE } from "constants/user.constants";

const AffiliatesPageRoute = {
	path: ROUTES.AFFILIATES,
	element: AffiliatesComponent, 
    permission: { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
    role: [USER_ROLE.ADMIN, USER_ROLE.AFFILIATE_MANAGER]
};

export default AffiliatesPageRoute;
