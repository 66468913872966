import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types'

import { useTranslation } from "core/hooks/useTranslation";

import { Col, Row } from 'antd';


import TabTableDashboardLayout from 'components/layouts/tab/table';
import Leaderboard from 'components/common/leaderboard';
import Filters from '../../../components/filters';

import { MARKETING_ENTITY_TYPE, TIME_PERIOD } from '../../../constants';
import { LEADERBOARD_DATA_TYPE, LEADERBOARD_TYPE } from 'components/common/leaderboard/constants';

import useFirstRender from 'hooks/useFirstRender';
import useMarketingTopCountries from '../../../hooks/useMarketingTopCountries';

import { isMobile } from 'utils/common';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';


const getDefaultFilters = () => (
    {
        period: {
            period: TIME_PERIOD.TODAY,
            from: null,
            to: null
        },
        dataType: MARKETING_ENTITY_TYPE.VIEW
    }
)

const TopCountries = ({
    updateLastUpdatedDate
}) => {
    const { t } = useTranslation();

    const [ filters, setFilters ] = useState(getDefaultFilters());

    const updateFilters = useCallback((newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters
            }
        })
    }, [])

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const { leaderBoardData, isLoading } = useMarketingTopCountries(filters, updateLastUpdatedDate)

    useFirstRender({
        afterFirstRenderCB: () => {
            updateFilters(getDefaultFilters())
        },
        dependencies: [globalProjectId, updateFilters]
    })

    return (
        <TabTableDashboardLayout
            header={{
                filters: isMobile() ? (
                    <Filters 
                        filters={filters} 
                        updateFilters={updateFilters} 
                        showDataType={true} 
                        dataTypeFields={
                            [
                                { value: MARKETING_ENTITY_TYPE.VIEW, text: t("backoffice.dashboard.view") },
                                { value: MARKETING_ENTITY_TYPE.VALID_CLICK, text: t("backoffice.dashboard.validClick") },
                                { value: MARKETING_ENTITY_TYPE.INVALID_CLICK, text: t("backoffice.dashboard.invalidClick") },
                            ]
                        }
                    />
                ) : undefined,
                content: !isMobile() ? (
                    <div className="rt--flex rt--align-center rt--justify-end rt--width-full">
                        <Filters 
                            filters={filters} 
                            updateFilters={updateFilters} 
                            showDataType={true}
                            dataTypeFields={
                                [
                                    { value: MARKETING_ENTITY_TYPE.VIEW, text: t("backoffice.dashboard.view") },
                                    { value: MARKETING_ENTITY_TYPE.VALID_CLICK, text: t("backoffice.dashboard.validClick") },
                                    { value: MARKETING_ENTITY_TYPE.INVALID_CLICK, text: t("backoffice.dashboard.invalidClick") },
                                ]
                            }
                        />
                    </div>
                ) : undefined
            }}
        >
            <div className='rt--chart-top'>
                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        <Leaderboard
                            leaders={leaderBoardData}
                            isLoading={isLoading}
                            title={t("backoffice.dashboard.top10Country")}
                            type={LEADERBOARD_TYPE.OPERATIONAL}
                            dataType={LEADERBOARD_DATA_TYPE.COUNTRY}
                            countText={
                                filters.dataType === MARKETING_ENTITY_TYPE.VIEW ? "backoffice.dashboard.views" :
                                filters.dataType === MARKETING_ENTITY_TYPE.VALID_CLICK ? "backoffice.dashboard.validClicks" :
                                filters.dataType === MARKETING_ENTITY_TYPE.INVALID_CLICK ? "backoffice.dashboard.invalidClicks" : null
                            }
                        />
                    </Col>
                </Row>
            </div>
        </TabTableDashboardLayout>
    )

}

TopCountries.propTypes = {
    updateLastUpdatedDate: PropTypes.func,
}

export default TopCountries;